import React, { useEffect, useState } from 'react';
import { Groupper, Button, Input, Field, Message, Table } from '../components';
import { useTitle } from '../Hooks';
import { Dropdown } from 'semantic-ui-react';
import { SalesCategory, SalesChannel } from '../Classes';
import { addCommas, bindChange } from '../Util';
import API from '../API';
import Validator from '../Validator';
import moment from 'moment';

var SalesReport = () =>{
	var { setTitle } = useTitle();
	var [startDate, setStartDate] = useState<number>(moment().subtract(7, 'd').unix());
	var [endDate, setEndDate] = useState<number>(moment().unix());
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);
	var [channels, setChannels] = useState<number[]>([SalesChannel.ONLINE, SalesChannel.PDV, SalesChannel.CALLCENTER, SalesChannel.B2B, SalesChannel.PERSONALIZED]);
	var [sales, setSales] = useState<SalesCategory[]>(null);

	useEffect(()=>{
		setTitle('Reporte de Productos');
	}, []);
	
	var downloadReport = ()=>{
		var { valid, prompts } = Validator({ startDate, endDate, channels }, {
			startDate: [{ rule: 'number', label: 'Fecha inicio' }],
			endDate: [{ rule: 'number', label: 'Fecha fin' }],
			channels:  [{ rule: 'minLength', params: [1], label: 'Opciones de datos'}]
		});
		setErrorPrompts(prompts);
		if(!valid) return;

		var mstart = moment.unix(startDate).format('DD/MM/YYYY');
		var mend = moment.unix(endDate).format('DD/MM/YYYY');
		API.downloadSalesReport(mstart, mend, channels).then(res=>{

		}).catch(err=>{
			setErrorPrompts(['Hubo un error inesperado descargando el reporte (LCL-1)']);
		});
	}

	var showReport = async(setLoading: (l: boolean)=>void)=>{
		var { valid, prompts } = Validator({ startDate, endDate, channels }, {
			startDate: [{ rule: 'number', label: 'Fecha inicio' }],
			endDate: [{ rule: 'number', label: 'Fecha fin' }],
			channels:  [{ rule: 'minLength', params: [1], label: 'Opciones de datos'}]
		});
		setErrorPrompts(prompts);
		if(!valid) return;

		var mstart = moment.unix(startDate).format('DD/MM/YYYY');
		var mend = moment.unix(endDate).format('DD/MM/YYYY');

		setLoading(true);
		API.showSalesReport(mstart, mend, channels).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
			return setSales(res.data);
		}).catch(err=>{
			setErrorPrompts(['Hubo un error inesperado descargando el reporte (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	return <div>
		<Groupper title='Reporte de Productos' width={500} actions={<>
			<Button text='Descargar' color='green' style={{ marginRight: 10 }} iconName='file alternate outline' onClick={downloadReport}/>
			<Button text='Buscar' color='black' onClick={showReport}/>
		</>}>
			<Field amount={2}>
				<Input label='Fecha de inicio' value={startDate} onChange={setStartDate} calendar calendarProps={{ mode: 'date', format: 'DD/MM/YYYY',}}/>
				<Input label='Fecha de fin' value={endDate} onChange={setEndDate} calendar calendarProps={{ mode: 'date', format: 'DD/MM/YYYY',}}/>
			</Field>
			<Field label='Tipo'>
				<Dropdown 
					selection 
					value={channels} 
					multiple
					placeholder='Opciones de datos' options={[
						{ value: SalesChannel.PDV, text: 'PDV' },
						{ value: SalesChannel.ONLINE, text: 'Online' },
						{ value: SalesChannel.CALLCENTER, text: 'Callcenter' },
						{ value: SalesChannel.PERSONALIZED, text: 'Personalizados' },
						{ value: SalesChannel.B2B, text: 'Empresariales' },
					]} 
					onChange={bindChange(setChannels, true)}
				/>
			</Field>
			{sales && sales.length === 0 ? <Message type='error' text='No se econtraron registros relacionados.' /> : null }
			<Message list={errorPrompts} type={'error'} />
		</Groupper>
		{!!sales && sales.length>0 && <>
			<Table
				striped
				details
				title='Resumen'
				style={{ maxWidth: 500, margin: 'auto', marginTop: 15 }}
				data={[
					['Total', addCommas(sales.reduce((a,b)=>a+(b.products.reduce((c,d)=>c+d.amount, 0)), 0))],
					null,
					...sales.map(a=>([
						a.category_name,
						addCommas(a.products.reduce((a,b)=>a+b.amount, 0), true, true)
					]))
				]}
			/>
			{sales.map(c => (
				<Table 
					striped
					divided
					title={c.category_name}
					headers={['ID', 'Producto', 'Cantidad', 'Gastos', 'Total']}
					centeredIndexes={[2, 3, 4]}
					style={{ maxWidth: 700, margin: 'auto', marginTop: 15 }}
					key={`cat-${c.category_id}`}
					button={
						<div style={{ color: 'gray', fontSize: 14 }}>ID: {c.category_id}</div>
					}
				>
					{c.products.length !== 0 ? <>
						{c.products.map(p => (
							<Table.Row
								key={`${c.category_id}-${p.product_id}`}
								collapsingIndexes={[2,3,4]}
								centeredIndexes={[2, 3]}
								data={[
									p.product_id,
									p.name,
									addCommas(p.amount, true, true),
									addCommas(p.product_cost, true, true),
									`$${addCommas(p.total)}`
								]}
							/>
						))}
						<Table.Row>
							<Table.Cell style={{ textAlign: 'right', fontWeight: 'bold' }} colSpan={4}>Total</Table.Cell>
							<Table.Cell style={{ textAlign: 'left', fontWeight: 'bold' }}>${addCommas(c.products.reduce((a,b)=>a+b.total, 0))}</Table.Cell>
						</Table.Row>
					</> : (
						<Table.Row><Table.Cell className='empty' colSpan={6}>No se encontraron registros</Table.Cell></Table.Row>
					)}
				</Table>
			))}
		</>}
	</div>
}

export default SalesReport;