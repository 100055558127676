import React, { useEffect, useState } from 'react';
import { addCommas } from '../Util';

import './style/cart_item.scss';
import classNames from 'classnames';
import ProductImage from './ProductImage';

interface CartItemProps{
	product: {
		product_id: number,
		product_name: string,
		price: number,
		price_gram?: number,
		price_discounted?: number,
		size?: string
	},
	showSize?: boolean,
	image: string,
	placeholderImage?: string,
	amount: number,
	price?: number,
	actions?: any,
	className?: string,
	style?: React.CSSProperties,
}

var CartItem = (props: CartItemProps)=>{
	var { product } = props;
	var granel = !!product.price_gram && product.price_gram>0;

	return <div className={classNames("co cart-item", props.className)} style={props.style}>
		<ProductImage image={props.image} fallback={props.placeholderImage} />
		<div className="info">
			<div className="name">{product.product_name}</div>
			{(!granel || props.showSize===true) && (
				<div className="size">{product.size}</div>
			)}
			<div className="price">
				{props.price ? (
					`$${addCommas(props.price*(props.amount || 1), true, true)}`
				) : product.price_discounted ? <>
					<div className="final">
						${addCommas(product.price_discounted*(props.amount || 1), true, true)}
					</div>
					<div className="discounted">
						${addCommas(product.price*(props.amount || 1), true, true)}
					</div>
				</> : (
					`$${addCommas(product.price*(props.amount || 1), true, true)}`
				)}
			</div>
			{props.actions}
		</div>
	</div>
}

export default CartItem;