function randomRange(min: number, max: number){
	return Math.floor(Math.random() * (max-min+1)+min);
}

function addCommas(num: number, fixed: boolean=true, hideZero: boolean=false){
	if (!num && num !== 0) return null;
	if(typeof num==='string'){
		num = parseFloat(num as string);
	}
	var c = num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	var dec = c.substring(c.length-2, c.length);
	return fixed ? (hideZero && dec == '00' ? c.slice(0, -3) : c) : c.slice(0, -3);
}

function toCents(amount: number){
	return Math.round(100 * amount);
}

// function localeContains(mainString, substring) {
// 	const normalizedMain = mainString.normalize('NFD');
// 	const normalizedSub = substring.normalize('NFD');
// 	return normalizedMain.indexOf(normalizedSub) !== -1;
// }

function filterSearch<T>(search: string, key: keyof T){
	var normal_search = search.normalize('NFD').toLowerCase();
	return (a: T)=>{
		if(!a[key]) return false;
		return (a[key].toString()).toLowerCase().normalize('NFD').indexOf(normal_search)!==-1;
	}
}

function isCardValid(number: string){
	number = number.replace(/\D/g,'');

	// visa
	var re = new RegExp("^4");
	if (number.match(re) != null) return true;
	
	// Mastercard 
	// Updated for Mastercard 2017 BINs expansion
	if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)) return true;
	
	// AMEX
	re = new RegExp("^3[47]");
	if (number.match(re) != null) return true;
	
	// Discover
	re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
	if (number.match(re) != null) return true;
	
	// Visa Electron
	re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
	if (number.match(re) != null) return true;
	
	return false;
}
    

export {
	addCommas,
	filterSearch,
	isCardValid,
	randomRange,
	toCents,
}