import React, { useEffect, useState } from 'react';
import { Header, Button, Input, Message, Groupper } from 'react-frontier';
import { SetLoading, Category, SiteConfig } from '../Classes';
import { Checkbox, Icon } from 'semantic-ui-react';
import { bindClick, bindFormChange } from '../Util';
import { SearchCategoryModal } from '../components';
import { useTitle } from '../Hooks';
import API from '../API';
import Validator from '../Validator';
import Toast from 'react-hot-toast';
import MDEditor, { commands } from '@uiw/react-md-editor/nohighlight';

var Variables = ()=>{
	var { setTitle } = useTitle();
	var [options, setOptions] = useState<SiteConfig & { landing_category_name: string }>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [categoryModal, setCategoryModal] = useState<boolean>(false);
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null)

	useEffect(() => {
		setTitle('Variables');
		if(!options) get();
	}, []);

	var get = ()=>{
		API.getSiteConfig().then(res=>{
			if(res.error) return setLoadError(res.message);
			setOptions(res.data);
		}).catch(err=>{
			return setLoadError('Hubo un error al obtener las opciones. (G-VBS-1)'); 
		})
	}

	var update = async(setLoading: SetLoading)=>{
		var { valid, prompts } = Validator(options, {
			shipping_limit: [{
				rule: 'number', label: 'Limite de envio',
			}],    
			shipping_local: [{
				rule: 'number', label: 'Envio Local',
			}],
			shipping_national: [{
				rule: 'number', label: 'Envio Nacional',
			}],
			shipping_cost: [{
				rule: 'number', label: 'Costo de envio',
			}],
			pickup_start: [{
				rule: 'number', label: 'Pickup Inicio',
			}],
			pickup_end: [{
				rule: 'number', label: 'Pickup Fin',
			}],
			shipping_end: [{
				rule: 'number', label: 'Shipping Fin',
			}],
			landing_category: [{
				rule: 'number', label: 'Categoria de inicio',
			}],
		});
		setErrorPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.setSiteConfig(options).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
			Toast.success('Opciones actualizadas!')
		}).catch(err=>{
			return setErrorPrompts(['Hubo un error al actualizar las opciones. (G-UPD-1)']); 
		}).finally(()=>{
			setLoading(false);
		})
	}

	var changeCategory = (category: Category)=>{
		options.landing_category_name = category.category_name;
		options.landing_category = category.category_id;
		setCategoryModal(false);
	}

	if(loadError){
		return <Header text='Error' subtext={loadError} />
	}

	if(!options){
		return <Header loading text='Cargando datos' />
	}

	var onEditOptions = bindFormChange(options, setOptions);

	return <>
		<Groupper title="Opciones" width={600} actions={
			<Button text="Guardar" color="black" onClick={update}/>
		}>
			<div className="fr option">
				<div className="info">
					<div className="title">Forzar OpenPay</div>
					<div className="description">Si se habilita, se usará OpenPay para todos los cobros con tarjeta. De lo contraro solo se usará para AMEX</div>
				</div>
				<Checkbox toggle checked={!!options.force_openpay} onChange={onEditOptions('force_openpay', true)} />
			</div>
			<div className="fr option last">
				<div className="info">
					<div className="title">Anuncio</div>
					<div className="description">Mensaje de anuncio</div>
				</div>
				<Input textarea placeholder="Anuncio" value={options.announcement} onChange={onEditOptions('announcement')} />
			</div>
			<div className="fr option divider">Envíos</div>
			<div className="fr option">
				<div className="info">
					<div className="title">Shipping</div>
					<div className="description">Habilitar o deshabilitar el shipping</div>
				</div>
				<Checkbox toggle checked={!!options.shipping_enabled} onChange={onEditOptions('shipping_enabled', true)} />
			</div>
			<div className="fr option">
				<div className="info">
					<div className="title">Limite de envio</div>
					<div className="description">Cantidad limite para que se use el costo de envío descontado</div>
				</div>
				<Input placeholder="Retorno" value={options.shipping_limit} onChange={onEditOptions('shipping_limit')} />
			</div>
			<div className="fr option">
				<div className="info">
					<div className="title">Costo de envio descontado</div>
					<div className="description">Costo de envío que se podrá cuando se llega a el limite de envío</div>
				</div>
				<Input placeholder="Costo de envio" value={options.shipping_cost} onChange={onEditOptions('shipping_cost')} />
			</div>
			<div className="fr option">
				<div className="info">
					<div className="title">Hora limite de envios mismo día</div>
					<div className="description">Hora a la que se dejará de permitiri agendar envios el mismo dia</div>
				</div>
				<Input placeholder="Shipping Fin" value={options.shipping_end} onChange={onEditOptions('shipping_end')} />
			</div>
			<div className="fr option last">
				<div className="info">
					<div className="title">Limitar solo entregas locales</div>
					<div className="description">Permitir solamente entregas a direcciones de códigos postales registrados</div>
				</div>
				<Checkbox toggle checked={!!options.only_zipcodes} onChange={onEditOptions('only_zipcodes', true)} />
			</div>
			<div className="fr option divider">Pickup</div>
			<div className="fr option">
				<div className="info">
					<div className="title">Pickup</div>
					<div className="description">Habilitar o deshabilitar el pickup</div>
				</div>
				<Checkbox toggle checked={!!options.pickup_enabled} onChange={onEditOptions('pickup_enabled', true)} />
			</div>
			{/* <div className="fr option">
				<div className="info">
					<div className="title">Pickup Inicio</div>
					<div className="description">Valor de inicio de pickup</div>
				</div>
				<Input placeholder="Pickup Inicio" value={options.pickup_start} onChange={onEditOptions('pickup_start')} />
			</div> */}
			<div className="fr option last">
				<div className="info">
					<div className="title">Hora limite pickup mismo día</div>
					<div className="description">Hora a la que se dejará de aceptar pickup del mismo día</div>
				</div>
				<Input placeholder="Pickup Fin" value={options.pickup_end} onChange={onEditOptions('pickup_end')} />
			</div>
			<div className="fr option divider">Landing</div>
			<div className="fr option">
				<div className="info">
					<div className="title">Titulo categoría inicio</div>
					<div className="description">Titulo que se mostrará en la categoría de inicio</div>
				</div>
				<Input placeholder="Pickup Inicio" value={options.landing_category_title} onChange={onEditOptions('landing_category_title')} />
			</div>
			<div className="fr option last">
				<div className="info">
					<div className="title">Categoria de inicio</div>
					<div className="description">La categoria que se muestra de inicio</div>
				</div>
				<Input placeholder="Categoria de inicio" value={options.landing_category_name} readonly button={(
					<Button style={{ minWidth: 0, paddingRight: 20 }} icon iconStyle={{ margin: 0 }} iconName="pen" onClick={bindClick(setCategoryModal, true)}/>
				)}/>
			</div>
			<div className="fr option divider">Productos</div>
			<div className="fr option last" style={{ display: 'block' }}>
				<div className="info" style={{ width: '100%', marginBottom: 15 }}>
					<div className="title">Horarios de envío</div>
					<div className="description">Información de envío que sale en la página de los productos</div>
				</div>
				<MDEditor 
					preview='edit'
					style={{ borderBottomLeftRadius: 12, borderBottomRightRadius: 12, width: '100%' }}
					value={options.shipping_info} 
					onChange={onEditOptions('shipping_info')} 
					height={300}
					data-color-mode='light'
					extraCommands={[
						{
							...commands.codeEdit,
							icon: <Icon name='pencil' style={{ margin: 0, fontSize: 18 }} />
						},
						{
							...commands.codePreview,
							icon: <Icon name='eye' style={{ margin: 0, fontSize: 18 }} />
						},
					]}
					commands={[
						commands.bold,
						commands.italic,
						commands.strikethrough,
						commands.divider,
						commands.hr,
						commands.quote,
						commands.code,
						commands.divider,
						commands.unorderedListCommand,
						commands.orderedListCommand,
					]}
				/>	
			</div>
			<Message type="error" list={errorPrompts} style={{ marginTop: 15 }} />
		</Groupper>
		<SearchCategoryModal
			open={categoryModal}
			onClose={bindClick(setCategoryModal)}
			onSelected={(c) => changeCategory(c)}
		/>
	</>
}

export default Variables;