import axios, { ResponseType } from 'axios';
import { APIResponse, UserLoginData, Product, EditProduct, SiteStats, SalesChannel, ListOrder, CreateOrderForm, Location, TaxInfoForm, RFC, FilterType, ListFilters, SortType, OrderItem, Category, SiteConfig, SitePage, SitePageAsset, EditSitePage, ProductDateReportData } from './Classes';
import { WebProduct } from '@cocoan/components/Classes';
import moment from 'moment';

const ROOT_URL = process.env.REACT_APP_API_URL
const DEBUG = process.env.REACT_APP_DEBUG;
const CDN_URL = process.env.REACT_APP_CDN_URL;
const PUBLIC_URL = process.env.REACT_APP_PAGE_PUBLIC;
const PLACEHOLDER_IMG = `${window.location.origin}/placeholder.webp`;

var CACHE : { [x: string]: APIResponse<any> } = {};

var getLogin = () : UserLoginData=>{
	var jsonstr = localStorage.getItem('user_data');
	if(!jsonstr) return null;
	try{
		var data = JSON.parse(jsonstr) as UserLoginData;
		if(!data || data.expire<=(moment().unix()-15)) return null;
		return data;
	}catch(e){
		return null;
	}
}

var logout = ()=>{
	localStorage.removeItem('user_data');
	window.dispatchEvent(new Event('user_data'));
	window.dispatchEvent(new Event('user_logout'));
}

interface PostOptions{
	login?: boolean,
	responseType?: ResponseType,
	endpoint?: string,
}

async function post<T=any>(endpoint: string, data?: any, opts?: PostOptions) : Promise<APIResponse<T>>{
	var options = {
		headers: {
			'token': '',
			'Content-Type': 'application/json'
		}
	}
	if(opts?.login!==false){
		var u = getLogin();
		if(u===null) return { error: true, code: 'PV-INVCRD', message: 'Las credenciales de accceso no son válidas (LCL-TKNCH-L).' }
		options.headers.token = u.token;
	}
	if(data instanceof FormData){
		options.headers['Content-Type'] = 'multipart/form-data';
	}

	try{
		var ENDPOINT = (opts && opts.endpoint) ? opts.endpoint : `${ROOT_URL}/${endpoint}`;
		if(DEBUG) console.log("POST "+ENDPOINT);
		var res = await axios.post(ENDPOINT, data, {
			...options,
			responseType: opts?.responseType || 'json'
		});
		if(DEBUG) console.log(` => Response: ${(parseInt(res.headers['content-length'])/1024).toFixed(2)} KB`);
		if(res.data && res.data.error && (res.data.code=='P-TKNE' || res.data.code=='AP-VFYT-1' || res.data.code=='P-TKNV')){
			logout();
		}
		return res.data as APIResponse<T>;
	}catch(err){
		if(DEBUG) console.error(err);
		return {
			error: true, 
			message: 'Hubo un error inesperado (API-LCL-1)',
			code: 'API-LCL-1',
		}
	}
}

var cachePost = async <T>(endpoint: string, key: string, data?: any) : Promise<APIResponse<T>>=>{
	if(CACHE[key]) return CACHE[key] as APIResponse<T>;
	var res = await post(endpoint, data);
	if(!res.error) CACHE[key] = res;
	return res;
}

var login = async (username: string, password: string)=>{
	var res = await post<UserLoginData>('login', { username, password }, { login: false });
	if(!res.error && res.data.token){
		localStorage.setItem('user_data', JSON.stringify(res.data));
		window.dispatchEvent(new Event('user_data'));
	}
	return res;
}

var register = async (first_name: string, last_name: string, email: string, alias: string, password: string, access: number)=>{
	return post('users/register', { first_name, last_name, email, alias, password, access});
}

var getUsers = async (offset: number, count?: number)=>{
	return post('users/list', { offset, count });
}

var getActiveUsers = ()=>{
	return post('users/list/active');
}

var getUser = async (user_id: number)=>{
	return post('users/get', { user_id });
}

var searchUsers = async (query: string)=>{
	return post('users/search', { query });
}

var updateUser = async (user_id: number, first_name: string, last_name: string, email: string, alias: string, access: number, department: string, show_public: boolean)=>{
	return post('users/update', { user_id, first_name, last_name, email, alias, access, department, show_public });
}

var changeUserPassword = async (user_id: number, password: string)=>{
	return post('users/password', { user_id, password});
}

var getProducts = async (offset: number, count?: number)=>{
	return post('products/list', { offset, count });
}

var getProduct = async (product_id: number, images: boolean=false)=>{
	return post('products/get', { product_id, images });
}

var getCollections = async ()=>{
	return post('categories/collections');
}

var getCategories = async (show_all: boolean = false)=>{
	return post('categories/categories', { show_all });
}

var getAllCategories = async (categories: boolean, collections: boolean)=>{
	return post('categories/list', { categories, collections });
}

var createCategory = async (category_name: string, description: string, online: boolean, pdv: boolean)=>{
	return post<{ category_id: number }>('categories/create', { category_name, description, online, pdv });
}

var getCategory = async (category_id: number, images: boolean=false)=>{
	return post('categories/get', { category_id, images });
}

var updateCategory = async (category_id: number, category_name: string, description: string, online: boolean, pdv: boolean, collection: boolean, products: Product[])=>{
	return post('categories/update', { category_id, category_name, description, online, pdv, collection, products});
}

var createProduct = async (name: string, description: string, message: string, priority: number, price_online: number, price_pdv: number, price_gram: number, size: string, granel: boolean, only_local: boolean, available_online: boolean, available_pdv: boolean, sat_code: number, category_id: number, categories: number[], product_cost: number)=>{
	return post('products/create', { name, description, message, priority, price_online, price_pdv, price_gram, size, granel, only_local, available_online, available_pdv, sat_code, category_id, categories, product_cost });
}

var updateProduct = async (product_id: number, data: EditProduct)=>{
	return post('products/update', { product_id, ...data });
}

var searchProducts = async (query: string, offset?: number, count?: number)=>{
	return post<WebProduct[]>('products/search', { query, offset, count});
}

var addCategoryProduct = async (category_id: number, product_id: number, remove: boolean=false)=>{
	return post('categories/product/add', { category_id, product_id, remove });
}

var removeCategoryProduct = async (category_id: number, product_id: number, remove: boolean=false)=>{
	return post('categories/product/remove', { category_id, product_id, remove });
}

var getOrders = async (web: boolean, pdv: boolean, offset: number, count?: number)=>{
	return post('orders/list', { web, pdv, offset, count });
}

var getOrderDetails = async (order_id: number, pdv: boolean, assigner: boolean=false, invoices: boolean=false)=>{
	return post('orders/get', { order_id, pdv, assigner, invoices });
}

var productCategoryEdit = async (product_id: number, category_id: number, remove: boolean)=>{
	return post('products/category/edit', { product_id, category_id, remove });
}

var getCodeCategories = async ()=>{
	return post('codes/categories');
}

var createCodeCategory = async (name: string, cost: number, local: boolean)=>{
	return post('codes/categories/create', { name, cost, local });
}

var getCodeCategory = async (category_id: number)=>{
	return post('codes/categories/category', { category_id });
}

var editCodeCategory = async (category_id: number, name: string, cost: number, enabled: boolean, local: boolean)=>{
	return post('codes/categories/edit', { category_id, name, cost, enabled, local });
}

var editCategoryCodes = async (category_id: number, zipcode: string, option: boolean)=>{
	return post('codes/categories/zipcodes', { category_id, zipcode, option });
}

var resendOrderEmail = async (order_id: number)=>{
	return post('orders/resend/email', { order_id });
}

var resendOrderSms = async (order_id: number, phone: string)=>{
	return post('orders/resend/sms', { order_id, phone });
}

var searchOrder = async (query: string, channel: number)=>{
	return post('orders/search', { query, channel });
}

var orderShippingDetails = async (order_id: number, shipping_handler: string, tracking_number: string, notification: number)=>{
	return post('orders/shipping/details', { order_id, shipping_handler, tracking_number, notification });
}

var orderUpdateDetails = async (order_id: number, owner_name: string, owner_last_name: string, owner_email: string, owner_phone: string)=>{
	return post('orders/update', { order_id, owner_name, owner_last_name, owner_email, owner_phone });
}

var addProductImage = async (product_id: number, image: File)=>{
	var data = new FormData();
	data.append('product_id', product_id.toString());
	data.append('image', image);
	return post<{ image_url: string }>('products/image/add', data)
}

var addCategoryImage = async (category_id: number, image: File)=>{
	var data = new FormData();
	data.append('category_id', category_id.toString());
	data.append('image', image);
	return post<{ image_url: string }>('categories/image/add', data)
}

var setImagePrimary = async (product_id: number, image: string)=>{
	return post<{ primary: string, images: string[] }>('products/image/primary', { product_id, image });
}

var deleteProductImage = async (product_id: number, image: string)=>{
	return post<{ primary: string, images: string[] }>('products/image/delete', { product_id, image });
}

var deleteCategoryImage = async (category_id: number)=>{
	return post('categories/image/delete', { category_id });
}

var deleteProduct = async (product_id: number, deleted: boolean)=>{
	return post('products/delete', { product_id, deleted });
}

var showSalesReport = async (date_start: string, date_end: string, channels: number[])=>{
	return post('reports/sales/show', { date_start, date_end, channels });
}

var showDatesReport = async (date_start: string, date_end: string)=>{
	return post('reports/dates/show' , { date_start, date_end });
}

var downloadSalesReport = async (date_start: string, date_end: string, channels: number[])=>{
	var res = (await post('reports/sales', { date_start, date_end, channels }, {
		responseType: 'blob'
	}) as any) as Blob;
	
	if(res.type==='application/json'){
		return JSON.parse(await res.text());
	}

	const link = document.createElement('a');
	const url = URL.createObjectURL(res);
	link.href = url;
	link.download = `COCOANSales-${date_start}.xlsx`;
	link.click();
	link.remove();

	return {
		error: false,
		code: null,
		message: null,
		data: null
	}
}

var downloadDatesReport = async (date_start: string, date_end: string)=>{
	var res = (await post('reports/dates', { date_start, date_end }, {
		responseType: 'blob'
	}) as any) as Blob;
	
	if(res.type==='application/json'){
		return JSON.parse(await res.text());
	}

	const link = document.createElement('a');
	const url = URL.createObjectURL(res);
	link.href = url;
	link.download = `COCOANDates-${date_start}.csv`;
	link.click();
	link.remove();

	return {
		error: false,
		code: null,
		message: null,
		data: null
	}
}

var searchProductsReport = async (filters: ListFilters[], date_start: string, date_end: string)=>{
	return post('reports/products' , { filters, date_start, date_end });
}

var downloadProductsReport = async (filters: ListFilters[], date_start: string, date_end: string)=>{
	var res = (await post('reports/products/download', { filters, date_start, date_end }, {
		responseType: 'blob'
	}) as any) as Blob;
	
	if(res.type==='application/json'){
		return JSON.parse(await res.text());
	}

	const link = document.createElement('a');
	const url = URL.createObjectURL(res);
	link.href = url;
	link.download = `COCOANproducts-${date_start}-${date_end}.csv`;
	link.click();
	link.remove();

	return {
		error: false,
		code: null,
		message: null,
		data: null
	}
}

var getClosedDates = async ()=>{
	return post('orders/dates/closed');
}

var createClosedDate = async (date: number, shipping: boolean, pickup: boolean)=>{
	return post('orders/dates/closed/create', { date, shipping, pickup });
}

var deleteClosedDate = async (date_id: number)=>{
	return post('orders/dates/closed/delete', { date_id });
}

var editOrderShippingDate = async (order_id: number, shipping_date: number)=>{
	return post('orders/shipping/date', { order_id, shipping_date });
}

var addUserImage = async (user_id: number, image: File)=>{
	var data = new FormData();
	data.append('user_id', user_id.toString());
	data.append('image', image);
	return post<{ image_url: string }>('users/image/add', data)
}

var deleteUserImage = async (user_id: number)=>{
	return post('users/image/delete', { user_id });
}

var getPaymentMethods = async ()=>{
	return post('orders/payment/methods');
}

var createOrderPayment = async (order_id: number, payment_method: number, amount: number, reference: string, date_paid: number, corte_id: number = null)=>{
	return post<{ payment_id: number }>('orders/payment/create',{ order_id, payment_method, amount, reference, date_paid, corte_id });
}

var cancelOrderPayment = async (payment_id: number)=>{
	return post('orders/payment/cancel',{ payment_id });
}

var getRfcList = async (cancelled: number = 0)=>{
	return post('tools/rfc/list', { cancelled });
}

var getRfc = async (tax_id: number)=>{
	return post('tools/rfc/get', { tax_id });
}

var createRfc = async (data: TaxInfoForm, deleted: number = 0)=>{
	return post('tools/rfc/create', { ...data, deleted });
}

var updateRfc = async (data: RFC)=>{
	return post('tools/rfc/update', { ...data });
}

var getSiteStats = ()=>{
	return post<SiteStats>('reports/stats');
}

var listChannel = (channel: SalesChannel, sort: number, offset: number=0, count: number=30)=>{
	return post<ListOrder[]>('orders/list/channel', { channel, sort, count, offset });
}

var listCustom = (query: string, filters: ListFilters[], sort: SortType, offset: number=0, count: number=30)=>{
	return post<ListOrder[]>('orders/list/custom', { query, filters, sort, offset, count });
}

var getCategoryProducts = (category_id: number, count: number=30, offset: number=0)=>{
	return post<{ products: WebProduct[] }>('categories/product/list', { category_id, count, offset });
}

interface CreateOrderCartItem{
	product_id: number,
	amount: number,
	price: number,
}

var createOrder = (form: CreateOrderForm, cart: CreateOrderCartItem[])=>{
	return post<{ 
		order_id: number,
		paid: boolean,
		order_key: string,
		order_hash: string,
		token: string,
	}>('orders/create', {
		...form,
		cart
	});
}

var getPdvList = ()=>{
	return post('pdvs/list');
}

var getPdv = (pdv_id: number)=>{
	return post('pdvs/get', { pdv_id });
}

var editPdv = (pdv_id: number, name: string)=>{
	return post('pdvs/edit', { pdv_id, name });
}

var getPdvCortes = (pdv_id: number, offset: number, count?: number)=>{
	return post('pdvs/cortes', { pdv_id, offset, count });
}

var getCorte = (corte_id: number)=>{
	return post('pdvs/corte', { corte_id });
}

var shippingList = (offset: number, count?: number, only_enabled: boolean=false)=>{
	return post('shipment/list', { offset, count, only_enabled });
}

var getShipmentMethod = (method_id: number)=>{
	return post('shipment/get', { method_id });
}

var editShipmentMethod = (method_id: number, type_id: number, name: string, cost: number, needs_location: boolean, local: boolean, description: string, location: Location)=>{
	return post('shipment/edit', { method_id, type_id, name, cost, needs_location, local, description, location });
}

var editOrderShipmentMethod = (order_id: number, method_id: number, shipping_cost: number)=>{
	return post('orders/shipment/edit', { order_id, method_id, shipping_cost });
}

var enableShipmentMethod = (method_id: number, enabled: boolean)=>{
	return post('shipment/enable', { method_id, enabled });
}

var assingOrders = (user_id: number, orders: number[] | number)=>{
	return post('orders/assign', { user_id, orders });
}

var unassingOrder = (order_id: number)=>{
	return post('orders/unassign', { order_id });
}

var prepareOrder = (order_id: number)=>{
	return post('orders/prepare', { order_id});
}

var unprepareOrder = (order_id: number)=>{
	return post('orders/unprepare', { order_id });
}

var getPendingOrders = ()=>{
	return post('orders/pending');
}

var deleteCategory = (category_id: number, deleted: boolean)=>{
	return post('categories/delete', { category_id, deleted });
}

var deleteUser = (user_id: number, deleted: boolean)=>{
	return post('users/delete', { user_id, deleted });
}

var addOrderComments = (order_id: number, comments: string)=>{
	return post('orders/comments', { order_id, comments });
}

var searchSatCode = (query: string)=>{
	return post('products/code', { query });
}

var createInvoice = (order_id: number, tax_id: number, payment_method: string, payment_form: number)=>{
	return post('orders/invoice/create', { order_id, tax_id, payment_method, payment_form });
}

var cancelInvoice = (invoice_id: number)=>{
	return post('orders/invoice/cancel', { invoice_id });
}

var downloadInvoice = (invoice_id: number, complete: boolean = false)=>{
	return post<{ invoice_url: string }>('orders/invoice/download', { invoice_id , complete: complete ? 1 : 0});
}

var cancelOrder = (order_id: number)=>{
	return post('orders/cancel', { order_id });
}

var editShippingDetails = (order_id: number, location: Location)=>{
	return post('orders/shipping/edit', { order_id, location });
}

var orderNotificationSms = async(order_id: number, phone: string)=>{
	return post('orders/notification/sms', { order_id, phone });
}

var unshippedOrder = async(order_id: number)=>{
	return post('orders/unshipped', { order_id });
}

var showDateInvoices = async (date_start: string, date_end: string)=>{
	return post('reports/invoices/show', { date_start, date_end });
}

var editOrderMethod = async (order_id: number, method_id: number)=>{
	return post('orders/edit/method', { order_id, method_id });
}

var editGiftDetails = async (order_id: number, gift_name: string, gift_last_name: string, gift_phone: string, gift_message: string )=>{
	return post('orders/edit/gift', { order_id, gift_name, gift_last_name, gift_phone, gift_message });
}

export interface OrderStatus{
   paid: boolean,
   ready: boolean,
   shipped: boolean
}

var showOrdersReport = async (date_start: string, date_end: string, channels: number[], status: OrderStatus )=>{
	return post('reports/orders', { date_start, date_end, channels, status });
}

var editOrderPayment = async (payment_id: number, payment_method: number, amount: number)=>{
	return post('orders/edit/payment', { payment_id, payment_method, amount });
}

var editOrderItem = async (order_id: number, item: OrderItem)=>{
	return post('orders/item/edit', { order_id, item });
}

var deleteOrderItem = async (order_id: number, item_id: number)=>{
	return post('orders/item/delete', { order_id, item_id });
}

interface AddProductItem{
	product_id: number,
	cost_single: number,
	quantity: number,
}

var addOrderItem = async (order_id: number, item: AddProductItem)=>{
	return post('orders/items/add', { order_id, item });
}

var saveCategorySort = (categories: number[])=>{
	return post('categories/sort/update', { categories });
}

var getPayment = (payment_id: number)=>{
	return post('orders/payment/get', { payment_id });
}

var getSiteConfig = ()=>{
	return post('tools/config/get');
}

var setSiteConfig = (config: SiteConfig)=>{
	return post('tools/config/update', { config });
}

var searchCategory = async (query: string, offset?: number, count?: number)=>{
	return post<Category[]>('categories/search', { query, offset, count});
}

var deleteOrderShippingLocation = async (order_id: number)=>{
	return post<Category[]>('orders/shipping/delete', { order_id});
}

var getSitePages = async()=>{
	return post<SitePage[]>('pages/list');
}

var getSiteAssets = async()=>{
	return post<SitePageAsset[]>('pages/assets/list');
}

var createSitePage = async(page_url: string, page_title: string, page_subtitle: string, page_contents: string, page_type: number, button_text: string, button_url: string)=>{
	return post('pages/create', { page_url, page_title, page_subtitle, page_contents, page_type, button_text, button_url });
}

var createSiteAsset = async(page_id: number, asset_type: number, asset_name: string, asset_description: string, image: File = null )=>{
	return post('pages/assets/create', { page_id, asset_type , asset_name , asset_description });
}

var getSitePage = async(page_id: number)=>{
	return post<SitePage>('pages/get', { page_id });
}

var getSiteAsset = async(asset_id: number)=>{
	return post<SitePageAsset>('pages/assets/get', { asset_id });
}

var updateSitePage = async(page_id: number, page_url: string, page_title: string, page_subtitle: string, page_contents: string, page_type: number, button_text: string, button_url: string)=>{
	return post('pages/update', { page_id, page_url, page_title, page_subtitle, page_contents, page_type, button_text, button_url });
}

var addPageBanner = async (page_id: number, image: File)=>{
	var data = new FormData();
	data.append('page_id', page_id.toString());
	data.append('image', image);
	return post<{ image_url: string }>('pages/banner/add', data)
}

var deletePageBanner = async (page_id: number)=>{
	return post('pages/banner/delete', { page_id });
}

var updateSiteAsset = async(asset_id: number, asset_type: number, asset_name: string, asset_description: string)=>{
	return post('pages/assets/update', { asset_id, asset_type, asset_name, asset_description });
}

var deleteSitePage = async (page_id: number, deleted: boolean)=>{
	return post('pages/delete', { page_id, deleted });
}

var deleteSiteAsset = async (asset_id: number, deleted: boolean)=>{
	return post('pages/assets/delete', { asset_id, deleted });
}

var addAssetImage = async (asset_id: number, image: File)=>{
	var data = new FormData();
	data.append('asset_id', asset_id.toString());
	data.append('image', image);
	return post<{ image_url: string }>('pages/assets/image/add', data)
}

var deleteAssetImage = async (asset_id: number)=>{
	return post('pages/assets/image/delete', { asset_id });
}

var deleteAssetPdf = async (asset_id: number)=>{
	return post('pages/assets/pdf/delete', { asset_id });
}

var addPDF = async (asset_id: number, pdf: File)=>{
	var data = new FormData();
	data.append('asset_id', asset_id.toString());
	data.append('pdf', pdf);
	return post<{ pdf_url: string }>('pages/assets/pdf/add', data)
}

var createPartialPayment = async (order_id: number, amount: number)=>{
	return post('orders/payment/partial/create', { order_id, amount })
}

var paidOrderPayment = async (payment_id: number)=>{
	return post('orders/payment/paid', { payment_id })
}

var paidOrder = async (order_id: number)=>{
	return post('orders/order/paid', { order_id })
}

var getCurrentCortes = async ()=>{
	return post('orders/order/cortes')
}

var getProductDateReport = async (date_start: number, date_end: number, products: number[]) : Promise<APIResponse<ProductDateReportData[]>>=>{
	var res = await post<string[]>('reports/products/date', { date_start, date_end, products });
	if(res.error) return {
		error: true,
		code: res.code,
		message: res.message,
		data: null
	};

	var data : ProductDateReportData[] = [];
	for(var i of res.data){
		var [date, product_id, product_name, quantity, total] = i.split(';;');
		console.log(i.split(';;'));
		data.push({
			date,
			product_id: parseInt(product_id),
			product_name,
			quantity: parseFloat(quantity),
			total: parseFloat(total),
		})
	}

	return {
		error: false,
		code: null,
		message: null,
		data,
	}
}

export default {
	CDN_URL,
	PUBLIC_URL,
	PLACEHOLDER_IMG,
	addPageBanner,
	addPDF,
	addAssetImage,
	addCategoryImage,
	addCategoryProduct,
	addOrderComments,
	addOrderItem,
	addProductImage,
	addUserImage,
	assingOrders,
	cancelInvoice,
	cancelOrder,
	cancelOrderPayment,
	changeUserPassword,
	createCategory,
	createClosedDate,
	createCodeCategory,
	createInvoice,
	createOrder,
	createOrderPayment,
	createPartialPayment,
	createProduct,
	createSitePage,
	createSiteAsset,
	createRfc,
	deleteAssetImage,
	deleteAssetPdf,
	deleteCategoryImage,
	deleteClosedDate,
	deleteCategory,
	deleteOrderItem,
	deleteOrderShippingLocation,
	deletePageBanner,
	deleteProduct,
	deleteProductImage,
	deleteSiteAsset,
	deleteSitePage,
	deleteUserImage,
	deleteUser,
	downloadInvoice,
	downloadDatesReport,
	downloadProductsReport,
	downloadSalesReport,
	editOrderMethod,
	editCategoryCodes,
	editCodeCategory,
	editGiftDetails,
	editOrderItem,
	editOrderPayment,
	editOrderShippingDate,
	editOrderShipmentMethod,
	editPdv,
	editShipmentMethod,
	editShippingDetails,
	enableShipmentMethod,
	getActiveUsers,
	getAllCategories,
	getCategories,
	getCategory,
	getCategoryProducts,
	getClosedDates,
	getCorte,
	getCodeCategories,
	getCodeCategory,
	getCollections,
	getCurrentCortes,
	getLogin,
	getOrderDetails,
	getOrders,
	getPayment,
	getPaymentMethods,
	getPdv,
	getPdvList,
	getPdvCortes,
	getPendingOrders,
	getProduct,
	getProductDateReport,
	getProducts,
	getRfc,
	getRfcList,
	getShipmentMethod,
	getSiteConfig,
	getSitePage,
	getSiteAssets,
	getSiteAsset,
	getSitePages,
	getSiteStats,
	getUser,
	getUsers,
	listChannel,
	listCustom,
	login,
	logout,
	orderNotificationSms,
	orderShippingDetails,
	orderUpdateDetails,
	paidOrder,
	paidOrderPayment,
	prepareOrder,
	productCategoryEdit,
	register,
	removeCategoryProduct,
	resendOrderEmail,
	resendOrderSms,
	saveCategorySort,
	searchCategory,
	searchOrder,
	searchProducts,
	searchProductsReport,
	searchSatCode,
	searchUsers,
	setImagePrimary,
	setSiteConfig,
	shippingList,
	showDateInvoices,
	showDatesReport,
	showOrdersReport,
	showSalesReport,
	unassingOrder,
	unprepareOrder,
	updateCategory,
	updateProduct,
	updateSiteAsset,
	updateSitePage,
	updateRfc,
	updateUser,
	unshippedOrder,
}