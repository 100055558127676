import { SemanticICONS } from "semantic-ui-react";

export interface APIResponse<T>{
	error: boolean,
	code: string,
	message: string,
	data?: T
}

export interface UserLoginData{ 
	user_id: number,
	email: string,
	first_name: string,
	last_name: string,
	password?: string,
	token: string,
	expire: number,
	access: number,
	show_public: boolean,
	name_complete: string,
}

export interface PaidForm{
	payment_method: number,
	payer_name: string,
	payer_phone: string,
	card_number: string,
	amount: number,
	reference: string,
	date_paid: number,
	complete_payment: boolean
}

export interface ClosedDate{
	date_id: number,
	shipping_type: number,
	date: number
}

export interface CreateCloseDate{
	date: number,
	shipping: boolean,
	pickup: boolean
}

export interface CategoryForm{
	category_name: string,
	description?: string,
	online: boolean,
	pdv: boolean,
	deleted: number,
	products: number[]
}

export interface ShippingForm{
	shipping_handler: string,
	tracking_number?: string,
	shipping_date: number,
	include_tracking_number: boolean,
	notification: SendType;
}

enum SendType{
	EMAIL = 1,
	SMS = 2,
	NONE = 3
}

export enum UserAccess{
	CAJERO = 1,
	REPORTES = 2,
	ADMIN = 10,
	SUPER_ADMIN = 11,
}

export interface Product{
	product_id: number,
	name: string,
	inventory: number,
	description?: string,
	message?: string,
	priority?: number,
	price_online: number,
	price_pdv: number,
	price_gram?: number,
	shortdesc?: string,
	size?: string,
	granel?: boolean,
	only_local: boolean,
	sat_code?: number,
	deleted: number,
	available_pdv: boolean,
	available_online: boolean,
	main_category?: number,
	categories?: number[],
	edit?: boolean,
	quantity?: number,
	tax_ieps: number,
	product_cost: number
}

export interface Order{
	order_id: number,
	order_key: string,
	total: number,
	shipping_cost: number,
	owner?: number,
	owner_name?: string,
	owner_last_name?: string,
	owner_email?: string,
	owner_phone?: string,
	order_status: number,
	order_method?: number,
	shipping_method: number,
	paid: boolean,
	discount_amount?: number,
	discount_percent?: number,
	shipping_date: number,
	shipped: boolean,
	date_shipped: number,
	shipping_handler?: string,
	tracking_number?: string,
	factura_external?: string,
	cancelled: boolean,
	canceller_id?: number,
	date_created: number,
	date_paid?: number,
	date_prepared?: number,
	date_factura?: number,
	comments?: string,
	live: boolean,
	pdv_id?: number,
	corte_id?: number,
	seller_id?: number,
	order_hash?: string,
	shipping: Shipping[]
	items: OrderItem[],
	payments: OrderPayment[],
	commission: number,
	city?: string,
	payment_method?: number,
	user_assigned?: number,
	user_assigned_name?: string,
	prepared: number,
	date_assigned: number,
	assigned_user?: {
		user_id: number,
		first_name: string,
		last_name: string,
		alias: string,
	},
	invoices?: Invoice[],
	invoiceLogs?: OrderInvoiceLog[],
	gift_name?: string
	gift_last_name?: string,
	gift_phone?: string,
	gift_message?: string,
	shipping_method_name: string,
	shipping_type?: number,
	shipping_type_name: string,
}

export interface Invoice {
	invoice_id: number,
	order_id: number,
	provider_id: number,
	tax_id?: number,
	payment_method: string,
	payment_form: number,
	external_id: string,
	user_creator: number,
	user_canceller?: number,
	cancelled: number,
	date_created: number,
	date_cancelled?: number,
	method_name?: string,
	provider_name?: string,
	rfc?: string,
	invoice_number: string
}

export interface PaymentMethod{
	method_id: number,
	method_name: string,
	method_name_localized: string,
	internal: number,
	sat_form: string,
	sat_method: string
}

export interface CodeCategory{
	category_id: number,
	name: string,
	cost: number,
	local: boolean,
	free_shipping: boolean,
	enabled: boolean
	zipcodes: ZipcodeData[]
}

export interface ZipcodeData{
	zipcode: string,
	category_id: number
}

export interface CodeCategoryForm{
	name: string,
	cost: number,
	local: boolean
}

export interface ShippingDetails{
	name: string,
	phone: string,
	street: string,
	exterior_number: string,
	interior_number: string,
	neighborhood: string,
	city: string,
	state: string,
	zipcode: string,
	country: string,
	comments: string,
	gift_sender?: string,
	local?: boolean,
	gift_message?: string
}

export interface OrderItem{
	item_id?: number,
	product_id: number,
	quantity: number,
	cost_single: number,
	cost: number,
	discount?: number,
	discount_percent?: number,
	discount_amount?: number,
	discount_name?: string,
	name: string,
	granel?: boolean,
	size: string,
	deleted?: boolean,
	user_adder?: number,
	user_adder_name?: string,
	user_deleter?: number,
	user_user_deleter_name?: string,
	date_added?: number,
	date_deleted?: number,
	product_cost: number,
	product_ieps: number,
	product_iva: number
}

export interface OrderPayment{
	order_id?: number;
	payment_method: number;
	method_name?: string,
	payer_name?: string;
	payer_phone?: string;
	card_number?: string;
	reference?: string;
	external_id?: string;
	executed?: boolean;
	payment_deadline?: number;
	payment_id?: number;
	paid?: boolean,
	amount?: number;
	date_paid?: number;
	date_created?: number;
}

export interface Category{
	category_id: number,
	category_name: string,
	priority: number,
	collection: boolean,
	description?: string,
	online: boolean,
	pdv: boolean,
	products: Product[],
	image: string,
	deleted: number | boolean,
	user_adder_name?: string,
	user_adder: number,
	user_deleter_name?: string,
	user_deleter?: number,
	date_created: number,
	date_deleted?: number
}

export interface WebProduct{
	product_id: number,
	product_name: string,
	price: number,
	price_discounted?: number,
	price_gram?: number,
	price_fix?: number,
	amount: number,
	size: string,
	message: string,
	images: string[],
	categories: {
		category_id: number,
		category_name: string,
		main: boolean,
	}[],
	metadata: {
		metadata_id: number,
		metadata_name: string,
		metadata_value_id: number,
		metadata_value: string,
	}[],
	related: {
		product_id: number,
		product_name: string,
		price: number,
		price_discounted?: number,
		price_gram?: number,
		size: string,
	}[]
}

export interface User{
	user_id: number,
	first_name: string,
	last_name: string,
	email: string,
	password: string,
	access: number,
	alias: string,
	image: string,
	show_public: boolean
}

export interface UserForm{
	first_name: string,
	last_name: string,
	email: string,
	password: string,
	alias: string,
	access: UserAccess
}

export interface UserData{
	user_id: number,
	first_name: string,
	last_name: string,
	email: string,
	access: number,
	department: string,
	alias: string,
	image: string,
	show_public: boolean,
	deleted: number | boolean,
	user_creator_na2me?: string,
	user_creator?: number,
	user_deleter_name?: string,
	user_deleter?: number,
	date_created?: number,
	date_deleted?: number
}

export interface EditProduct{
	product_id?: number,
	name: string, 
	description: string, 
	message: string, 
	priority: number, 
	price_online: number, 
	price_pdv: number, 
	price_gram: number, 
	size: string, 
	granel: boolean, 
	only_local: boolean,
	sat_code?: number,
	available_online: boolean, 
	available_pdv: boolean, 
	main_category: number,
	deleted: number | boolean,
	product_cost: number,
	categories: number[],
	tax_ieps: number,
	images?: {
		primary: string,
		images: string[]
	},
	user_creator_name?: string,
	user_creator?: number,
	user_deleter_name?: string,
	user_deleter?: number,
	date_created?: number,
	date_deleted?: number
}

export interface RFC{
	tax_id: number,
	legal_name: string,
	rfc: string,
	email: string,
	deleted: boolean,
	name: string,
	regimen: string,
	use_cfdi: string,
	location: Location,
	phone?: string
}

export interface TaxInfoForm{
	rfc: string, 
	regimen: string, 
	legal_name: string, 
	email: string, 
	use_cfdi: string, 
	location: Location
}

export interface SiteStats{
	total: number,
	orders_pdv: number,
	orders_online: number,
	pending_online: number,
	orders_today: number,
	online_today: number,
	pdv_today: number,
	pending_b2b: number,
	pending_personalized: number,
	pending_prepared?: number,
}

export interface ListOrder{
	order_id: number,
	order_method: SalesChannel,
	shipping_method: ShippingMethod,
	shipping_type: ShippingType,
	owner_name: string,
	owner_last_name: string,
	owner_email: string,
	shipped: boolean,
	date_created: number,
	date_paid: number,
	shipping_date: number,
	total: number,
	paid: boolean,
	prepared: boolean,
	date_shipped: number,
	user_assigned: number,
	user_assigned_alias: string,
	user_assigned_name: string,
}

export enum ShippingMethod{
	SHIPPING = 1,
	PICKUP = 50,
	PICKUP_HOUR = 51,
}

export enum SalesChannel{
	PDV = 1,
	ONLINE = 2,
	B2B = 20,
	PERSONALIZED = 21,
	CALLCENTER = 22,
}


export enum PdvChannel{
	INFO = 1,
	CORTES = 2
}

export interface SalesCategory{
	category_id: number,
	category_name: string,
	products: {
		product_id: number,
		name: string,
		product_cost: number,
		amount: number,
		total: number,
		category: number,
	}[]
}

export type SetLoading = (l: boolean)=>void;

export interface CreateOrderForm{
	owner_name: string,
	owner_last_name: string,
	owner_email: string,
	owner_phone: string,
	referal?: string,
	order_method: SalesChannel,
	shipping_method: ShippingMethod,
	shipping_date: number,
	send_email: boolean,
	paid: boolean,
	payments: {
		method_id: number,
		method_name: string,
		amount: number,
		date_paid: number,
	}[],
	tax_id?: number
}

export interface Pdv{
	pdv_id: number,
	name: string,
	access_token?: string,
	machine_id: string,
	deleted: boolean,
	date_created: number,
	date_deleted?: number
}

interface UserShort{
	user_id: number;
	name: string,
	first_name: string;
	last_name: string;
	alias: string;
	email: string;
}

export interface Corte{
	corte_id: number;
	start_date?: number,
	end_date?: number,
	total: number,
	pdv_id: number,
	user_start?: UserShort,
	user_end?: UserShort,
	date_created: number,
	date_cancelled?: number,
	cancelled?: boolean,
	orders: {
		order_id: number,
		date_paid: number,
		total: number,
		seller_id: number,
		seller?: UserShort,
	}[];
	payments: {
		method_id: number,
		method_name: string,
		amount: number,
		change_amount: number,
	}[],
	cart: {
		product_id: number,
		product_name: string,
		quantity: number,
		cost_single: number,
		total: number,
	}[],
}

export interface Location{
	location_id?: number,
	name?: string,
	phone?: string,
	street: string,
	exterior_number: string,
	interior_number?: string,
	neighborhood: string,
	city: string,
	state: string,
	zipcode: string,
	country?: string,
	lattitude?: number,
	longitude?: number,
	place_id?: string,
	comments?: string,
}

export interface Shipping{
	method_id: number;
	type_id: number;
	type_name: string;
	name: string;
	cost: number;
	location_id?: number;
	needs_location: boolean;
	local: boolean;
	description?: string;
	enabled: boolean;
	date_disabled?: number;
	location?: Location;
}

export interface SatCode{
	description: string,
	key: number
}

export interface OrderInvoiceLog{
	log_id: number;
	order_id: number;
	tax_id?: number;
	message: string;
	date_created?: number;
	rfc?: string
}

export enum ShippingType{
	SHIPPING = 1,
	PICKUP = 20,
}


export type Icons = SemanticICONS 
| 'seat'
| 'cash register'
| 'user tag'
| 'user tie'
| 'user cog'
| 'theater masks'
| 'face frown open'
| 'pen'
| 'hand holding usd'
| 'pdf file'
| 'face frown'
| 'palette'
;

export enum FilterType{
	FALSE = 0,
	TRUE = 1,
	UNDEFINED = 2,
}

export enum SortType{
	CREATION_DATE = 1,
	SHIPPING_DATE = 2,
}

export interface ListFilters{
	user_assigned?: number,
	paid?: FilterType,
	prepared?: FilterType,
	shipped?: FilterType,
	channel?: SalesChannel,
	cancelled?: FilterType,
}

export interface ReportOrder{
	order_id: number,
	owner_name: string,
	owner_last_name: string,
	shipped: boolean,
	paid: boolean,
	shipping_method: string,
	shipping_method_name: string,
	shipping_type: number,
	shipping_type_name: string,
	total: number,
	shipping_cost: number,
	shipping_date: number,
	gift_message: string,
	ready: boolean,
	products: { 
		order_id: number,
		product_id: number,
		product_name: string,
		quantity: number,
		granel: boolean,
		cost_single: number,
	}[]
}

export interface OrderStatus{
   paid: boolean,
   ready: boolean,
   shipped: boolean
}

export interface ProductReport{
	orders: number;
	date: string;
	quantity: number;
	product_id: number;
	granel: number;
	name: string;
}

export interface PaymentInfo{
	payment_id: number,
	paid: boolean,
	payment_method?: number,
	method_name_localized: string,
	external_id?: number,
	reference?: string,
	date_created: number,
	date_paid: number,
	amount: number,
	cancelled: boolean,
	pdv_name: string,
	pdv_id: string,
	external_payment: ExternalPayment,
}

interface ExternalPayment{
	status: string,
	amount: number,
	date: number,
	reference: string,
	bank: string,
	card_digits: string,
	error: boolean,
	error_message: string,
}

export interface newProduct{
	product_id: number,
	product_name: string,
	message?: string,
	price_pdv: number,
	price: number,
	description: string,
	size?: string,
	price_gram?: number,
	quantity?: number,
	product_cost: number,
	tax_ieps: number,
}

export interface SiteConfig{
	shipping_limit: number,
	shipping_cost: number,
	shipping_end: number,
	shipping_enabled: boolean,
	announcement: string,
	landing_category: number
	landing_category_title: string,
	force_openpay: number,
	only_zipcodes: boolean,
	shipping_info: string,

	pickup_enabled: boolean,
	pickup_start: number,
	pickup_end: number,

	sms_delivered: string,
	sms_shipped: string,
	sms_prepared: string,
}

export interface SitePageType{
	type_id: number,
	type_name: string,
}

export interface SitePage{
	page_id: number,
	page_url: string,
	page_title: string,
	page_subtitle?: string,
	button_text: string,
	button_url: string,
	page_contents?: string,
	page_type: number,
	deleted: boolean,
	user_creator: number,
	user_deleter?: number,
	date_created?: number,
	date_deleted?: number,
	pdf?: string,
	assets?:  SitePageAsset [],
	button?: boolean,
	banner?: string,
}

export interface EditSitePage{
	page_id?: number,
	page_url: string,
	page_title: string,
	page_subtitle?: string,
	button_text: string,
	button_url: string,
	page_contents?: string,
	page_type: number,
	deleted: boolean,
	user_creator?: number,
	user_deleter?: number,
	date_created?: number,
	date_deleted?: number,
	pdf?: string,
	assets?:  SitePageAsset [],
	button?: boolean,
	banner?: string,
}

export interface EditSiteAsset{
	asset_id?: number,
	asset_type: number,
	asset_name: string,
	asset_description?: string | any,
	deleted?: boolean,
	user_creator?: number,
	user_deleter?: number,
	date_created?: number,
	date_deleted?: number,
	image?: string,
	pdf?: string,
}

export interface SitePageAssetType{
	type_id: number,
	type_name: string,
}

export enum SiteAssetType{
	PDF = 1,
	IMAGE = 2,
}

export interface SitePageAsset{
	page_id?: number,
	asset_id?: number,
	asset_type: SiteAssetType,
	asset_name: string,
	asset_description?: string | any,
	deleted: boolean,
	user_creator?: number,
	user_deleter?: number,
	date_created?: number,
	date_deleted?: number,
	pdf?: string,
	image?: string,
}

export interface ProductDateReportData{
	date: string,
	product_id: number,
	product_name: string,
	quantity: number,
	total: number
}

export enum SiteType{
	PDF = 1,
	IMAGE_GALLERY = 2,
	TEXT = 3
}